/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const styles = {
  container: {
    border: '1px solid #ccc',
    padding: '8px',
    borderRadius: '4px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  tag: {
    backgroundColor: '#edf2f7',
    color: 'black',
    padding: '4px 8px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  closeButton: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'black',
  },
  select: {
    border: 'none',
    outline: 'none',
    flex: '1',
  },
};

function MultiSelect({
  options,
  selectedOptions,
  setSelectedOptions,
  name,
  id,
}) {
  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (value && !selectedOptions.includes(value)) {
      setSelectedOptions([...selectedOptions, value]);
    }
    event.target.value = ''; // Сбрасываем значение селекта
  };

  const handleRemove = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  };

  return (
    <div>
      <div style={styles.container}>
        {selectedOptions.map((option) => (
          <div key={option} style={styles.tag}>
            {option}
            <span style={styles.closeButton} onClick={() => handleRemove(option)}>
              ×
            </span>
          </div>
        ))}
        <select name={name} id={id} style={styles.select} onChange={handleSelectChange}>
          <option value="">
            Select...
          </option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default MultiSelect;
